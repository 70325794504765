import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import CloseButton from "../../buttons/CloseButton";
import RectButton from "../../buttons/RectButton";

import { Body12 } from "../../styles/TextStyles";
import TextEditor from "../../text/TextEditor";
import ClearableInput from "../../text/TextField";
import { EditorState, Editor, RichUtils, convertToRaw } from "draft-js";
import firebase from "gatsby-plugin-firebase";
import { SelectableEmoji } from "../../lottie/SelectableEmoji";
import { format, getTime, getUnixTime } from "date-fns";

export default function NoteEditor({ show }) {
  const [selectedArray, setSelectedArray] = useState([]);
  const [textState, setTextState] = useState(EditorState.createEmpty());
  const [titleState, setTitleState] = useState("");

  function appendEmo(id) {
    selectedArray.push(id);
    setSelectedArray(selectedArray);
    console.log(`selected ${selectedArray}`);
  }

  function removeEmo(id) {
    const index = selectedArray.indexOf(id);
    const temp = selectedArray;
    temp.splice(index, 1);
    setSelectedArray(temp);
    console.log(`selected ${temp}`);
  }

  function setText(text) {
    setTextState(text);
    console.log(`textState is ${textState}`);
  }
  const content = textState.getCurrentContent();
  const rawVal = convertToRaw(content);
  const jsString = JSON.stringify(rawVal);
  const currEmail = firebase.auth().currentUser.email;
  const name = currEmail === "qin.jinghuang@dhs.sg" ? "JH" : "Ashley";

  function addNote() {
    show();
    firebase
      .firestore()
      .collection("lovenotes")
      .doc(getUnixTime(new Date()).toString())
      .set({
        name: name,
        emo: selectedArray,
        text: jsString,
        time: getUnixTime(new Date()),
        ashread: false,
        jhread: false,
        title: titleState,
      })
      .then(console.log("hahahahahah"))
      .catch((error) => console.log("error", error));
  }

  return (
    <Wrapper>
      <BoxWrapper>
        <Title>{titleState ? titleState : "New Love Note"}</Title>
        <ClearableInput title="Title" handle={setTitleState} />
        <SelectableEmoji
          array={selectedArray}
          appendEmo={appendEmo}
          removeEmo={removeEmo}
        />
        <EditorWrapper>
          <TextEditor setText={setText} />
        </EditorWrapper>
        <ButtonWrapper>
          <RectButton title="Post Love Note" onClick={() => addNote()} />
          <CloseButton onClick={() => show()} />
        </ButtonWrapper>
      </BoxWrapper>
    </Wrapper>
  );
}

const animation = keyframes`
  0% {box-shadow: 0px 4px 4px rgba(177, 169, 185, 0),
    5px 5px 5px rgba(177, 169, 185, 0),
    inset 4px -4px 4px rgba(177, 169, 185, 0); transform: scale(0.95) ;}
  100% {box-shadow: 0px 4px 4px rgba(177, 169, 185, 0.2),
    5px 5px 5px rgba(177, 169, 185, 0.2),
    inset 4px -4px 4px rgba(177, 169, 185, 0.1);}
`;

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(20px);
  z-index: 1;
  align-items: center;
  justify-content: center;
  @media (max-width: 450px) {
    backdrop-filter: blur(40px);
  }
`;
const BoxWrapper = styled.div`
  @media (max-width: 450px) {
    display: grid;
    justify-content: center;
    align-items: flex-start;
    width: 90vw;
    height: 90vh;
    padding: 20px;
    gap: 10px;

    grid-template-rows: 1fr 2fr 3fr 20fr 3fr;
  }

  @media (min-width: 450px) {
    display: grid;
    align-items: center;
    min-height: 70vh;
    max-width: 95vw;
    min-width: 50vw;
    padding: 20px;
    gap: 20px;
    background: #ffffff;
    box-shadow: 2px 2px 5px rgba(177, 169, 185, 0.25),
      inset 4px 4px 4px rgba(177, 169, 185, 0.2),
      inset -4px -4px 4px rgba(177, 169, 185, 0.2);
    border-radius: 30px;
    animation: ${animation} 1s forwards;
  }
`;

const Title = styled(Body12)`
  vertical-align: center;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 20px;

  @media (max-width: 450px) {
    padding-bottom: 0;
    font-size: 14px;

    height: 20px;
  }
`;

const ButtonWrapper = styled.div`
  display: grid;
  height: 50px;
  grid-template-columns: 200px 200px;
  justify-content: center;
  justify-items: center;
  align-items: center;
`;

const EditorWrapper = styled.div`
  box-sizing: border-box;
`;
